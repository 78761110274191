import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { Box, Flex, Radio, RadioGroup, Text, useToast } from '@chakra-ui/core';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import Button from '../../../components/Button';
import Input from '../../../components/Form/Input';
import Select from '../../../components/Form/Select';
import TextArea from '../../../components/Form/TextArea';
import { HTTP } from '../../../shared/constants';
import FormHelper from '../../../shared/helpers/FormHelper';
import apiGateway from '../../../shared/services/apiGateway';

const BuyersRegister: React.FC = () => {
    const formRef = useRef<FormHandles>(undefined);
    const navigate = useNavigate();
    const toast = useToast();
    const { id } = useParams() as any;

    const [isEditMode, setIsEditMode] = useState(false);
    const [type, setType] = useState('FISICA');
    const [states, setStates] = useState([]);
    const [buyer, setBuyer] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState('');

    const handleChangeState = useCallback(
        async (stateOption) => {
            const stateValue = stateOption.value;

            if (stateValue) {
                const state = states.find((item) => item.name === stateValue);

                if (!state) return;

                setSelectedState(state.name);

                await apiGateway
                    .get(`/location/states/${state.id}/cities`)
                    .then((response) => {
                        setCities(response.data);
                    });
            }
        },
        [states]
    );

    const handleZipcodeChange = useCallback(async () => {
        const zipcode = formRef.current.getFieldValue('address.zipcode');
        if (zipcode.length >= 8) {
            const response = await fetch(
                `https://viacep.com.br/ws/${zipcode}/json/`
            );
            const data = await response.json();
            if (!data.erro) {
                formRef.current?.setFieldValue(
                    'address.street',
                    data.logradouro
                );

                const state = states.find((item) => item.iso_code === data.uf);
                formRef.current?.setFieldValue('address.state', state.name);

                await handleChangeState({ value: state.name }).then(() => {
                    formRef.current?.setFieldValue(
                        'address.neighborhood',
                        data.bairro
                    );
                    formRef.current?.setFieldValue(
                        'address.city',
                        data.localidade
                    );
                });
            }
        }
    }, [formRef, states]);

    const fetchBuyerData = useCallback(async () => {
        try {
            const response = await apiGateway.get(`/buyers/${id}`);
            if (response.status === HTTP.STATUS.SUCCESS) {
                setBuyer(response.data);
                setType(response.data.type);
                apiGateway.get('/location/states').then((response2) => {
                    setStates(response2.data);
                });
            }
        } catch (error) {
            console.log(error);
            toast({
                title: 'Erro ao carregar os dados do cliente.',
                description:
                    'Não foi possível carregar os dados do cliente. Tente novamente.',
                status: 'error',
                duration: 4000,
                isClosable: true
            });
        }
    }, [id]);

    const handleSubmit = useCallback(
        async (formData: any) => {
            try {
                if (isEditMode) {
                    const response = await apiGateway.put(`/buyers/${id}`, {
                        name: formData.name,
                        type: type,
                        telephone: formData.telephone,
                        document: formData.document,
                        email: formData.email,
                        address: {
                            zipcode: formData.address.zipcode,
                            city: formData.address.city,
                            neighborhood: formData.address.neighborhood,
                            street: formData.address.street,
                            number: formData.address.number,
                            complement: formData.address.complement,
                            state: formData.address.state
                        },
                        observations: formData.observations
                    });

                    if (response.status === HTTP.STATUS.SUCCESS) {
                        FormHelper.onSuccess(
                            'Cliente Atualizado',
                            'Cliente atualizado com sucesso',
                            toast
                        );
                        navigate('/clients');
                    }
                } else {
                    const response = await apiGateway.post('/buyers', {
                        name: formData.name,
                        type: formData.type,
                        telephone: formData.telephone,
                        document: formData.document,
                        email: formData.email,
                        address: {
                            country: 'Brasil',
                            zipcode: formData.address.zipcode,
                            city: formData.address.city,
                            neighborhood: formData.address.neighborhood,
                            street: formData.address.street,
                            number: formData.address.number,
                            complement: formData.address.complement,
                            state: formData.address.state
                        },
                        observations: formData.observations
                    });

                    if (response.status === HTTP.STATUS.CREATED) {
                        FormHelper.onSuccess(
                            'Cadastro Realizado',
                            'Cliente cadastrado com sucesso',
                            toast
                        );
                        FormHelper.reset(formRef);
                        navigate('/clients');
                    }
                }
            } catch (error) {
                if (error instanceof Yup.ValidationError) {
                    FormHelper.showErrors(formRef, error, toast);
                }
            }
        },
        [isEditMode, id, navigate, toast, type]
    );

    useEffect(() => {
        if (id) {
            setIsEditMode(true);
            fetchBuyerData();
        } else {
            apiGateway.get('/location/states').then((response) => {
                setStates(response.data);
            });
        }
    }, [id]);

    useEffect(() => {
        if (buyer && states.length > 0) {
            const state = buyer.address.state;
            formRef.current.setFieldValue('address.state', state);
            handleChangeState({ value: state }).finally(() => {
                setSelectedState(state);
            });
        }
    }, [buyer, states]);

    if (isEditMode && !buyer && states.length === 0) return;

    return (
        <Form
            ref={formRef}
            onSubmit={handleSubmit}
            autoComplete="off"
            style={{
                width: '100%'
            }}
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
        >
            <Flex
                width="100%"
                flexDirection="column"
                backgroundColor="white"
                px="24px"
                py="16px"
                position="relative"
                fontWeight="600"
                fontSize="14px"
            >
                <Text color="purple.500" fontSize="20px" mt="16px">
                    {isEditMode ? 'Editar Cliente' : 'Cadastrar Cliente'}
                </Text>

                <Flex
                    minWidth="100%"
                    justifyContent="space-between"
                    flexWrap="wrap"
                    mt="8px"
                >
                    <RadioGroup>
                        <Flex
                            minWidth="100%"
                            justifyContent="space-between"
                            flexWrap="wrap"
                            mt="8px"
                            style={{ gap: '16px' }}
                        >
                            <Radio
                                name="type"
                                value="FISICA"
                                fontWeight="500"
                                onChange={(e) => setType(e.currentTarget.value)}
                                defaultIsChecked={type === 'FISICA'}
                            >
                                Pessoa Física
                            </Radio>
                            <Radio
                                name="type"
                                value="JURIDICA"
                                fontWeight="500"
                                defaultIsChecked={type === 'JURIDICA'}
                                onChange={(e) => setType(e.currentTarget.value)}
                            >
                                Pessoa Jurídica
                            </Radio>
                        </Flex>
                    </RadioGroup>
                </Flex>

                <Flex
                    minWidth="100%"
                    justifyContent="space-between"
                    flexWrap="wrap"
                    mt="8px"
                >
                    <Box width="48%">
                        <Input
                            name="name"
                            label="Nome Completo"
                            isRequired
                            size="sm"
                            defaultValue={buyer?.name || ''}
                        />
                    </Box>
                    <Box width="48%">
                        {!buyer && (
                            <Input
                                name="document"
                                label={type === 'FISICA' ? 'CPF' : 'CNPJ'}
                                mask={
                                    type === 'FISICA'
                                        ? '999.999.999-99'
                                        : '99.999.999/9999-99'
                                }
                                size="sm"
                                defaultValue={buyer?.document || ''}
                            />
                        )}
                        {buyer && (
                            <Input
                                name="document"
                                label={type === 'FISICA' ? 'CPF' : 'CNPJ'}
                                size="sm"
                                defaultValue={buyer.document}
                            />
                        )}
                    </Box>
                </Flex>

                <Flex
                    minWidth="100%"
                    justifyContent="space-between"
                    flexWrap="wrap"
                    mt="8px"
                >
                    <Box width="48%">
                        <Input
                            name="email"
                            label="Email"
                            size="sm"
                            defaultValue={buyer?.email || ''}
                        />
                    </Box>
                    <Box width="48%">
                        <Input
                            name="telephone"
                            label="Telefone"
                            mask={buyer ? '' : '(99) 99999-9999'}
                            size="sm"
                            defaultValue={buyer?.telephone || ''}
                        />
                    </Box>
                </Flex>

                <Flex
                    minWidth="100%"
                    justifyContent="space-between"
                    flexWrap="wrap"
                    mt="8px"
                >
                    <Box width="30%">
                        <Input
                            name="address.zipcode"
                            label="CEP"
                            mask={buyer ? '' : '99999-999'}
                            size="sm"
                            onBlur={() => handleZipcodeChange()}
                            defaultValue={buyer?.address?.zipcode || ''}
                        />
                    </Box>
                    <Box width="30%">
                        <Select
                            name="address.state"
                            label="Selecione o estado"
                            onChange={(e) => handleChangeState(e.currentTarget)}
                            size="sm"
                        >
                            <option value="">Selecione um estado</option>
                            {states.map((state) => (
                                <option key={state.id} value={state.name}>
                                    {state.name}
                                </option>
                            ))}
                        </Select>
                    </Box>
                    <Box width="30%">
                        {isEditMode && buyer && selectedState && (
                            <Select
                                key={cities.length}
                                name="address.city"
                                label="Selecione a cidade"
                                size="sm"
                                defaultValue={buyer.address.city}
                            >
                                <option>Selecione uma cidade</option>
                                {cities.map((city) => (
                                    <option key={city.id} value={city.name}>
                                        {city.name}
                                    </option>
                                ))}
                            </Select>
                        )}
                        {!isEditMode && (
                            <Select
                                name="address.city"
                                label="Selecione a cidade"
                                size="sm"
                            >
                                <option>Selecione uma cidade</option>
                                {cities.map((city) => (
                                    <option key={city.id} value={city.name}>
                                        {city.name}
                                    </option>
                                ))}
                            </Select>
                        )}
                    </Box>
                </Flex>

                <Flex
                    minWidth="100%"
                    justifyContent="space-between"
                    flexWrap="wrap"
                    mt="8px"
                >
                    <Flex width="30%">
                        <Input
                            name="address.neighborhood"
                            label="Bairro"
                            size="sm"
                            defaultValue={buyer?.address?.neighborhood || ''}
                        />
                    </Flex>
                    <Flex width="30%">
                        <Input
                            name="address.street"
                            label="Rua"
                            size="sm"
                            defaultValue={buyer?.address?.street || ''}
                        />
                    </Flex>

                    <Flex width="30%">
                        <Input
                            name="address.number"
                            label="Nº"
                            size="sm"
                            defaultValue={buyer?.address?.number || ''}
                        />
                    </Flex>
                </Flex>

                <Flex width="100%">
                    <Input
                        name="address.complement"
                        label="Complemento"
                        size="sm"
                        defaultValue={buyer?.address?.complement || ''}
                    />
                </Flex>

                <Flex
                    minWidth="100%"
                    justifyContent="space-between"
                    flexWrap="wrap"
                    mt="8px"
                >
                    <Box width="100%" mb="16px">
                        <TextArea
                            name="observations"
                            label="OBS"
                            size="sm"
                            mb="4px"
                            defaultValue={buyer?.observations || ''}
                        />
                    </Box>
                </Flex>

                <Button
                    type="submit"
                    width="100%"
                    backgroundColor="purple.500"
                    height="48px"
                    mt="24px"
                >
                    {isEditMode ? 'Atualizar' : 'Cadastrar'}
                </Button>
            </Flex>
        </Form>
    );
};

export default BuyersRegister;
