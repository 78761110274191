import React, { useCallback } from 'react';

import {
    Button,
    Divider,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    useToast
} from '@chakra-ui/core';
import PictureZoom from '../../../modules/order/components/PictureZoom';
import { HTTP } from '../../../shared/constants';
import apiGateway from '../../../shared/services/apiGateway';
import CurrencyUtils from '../../../shared/utils/CurrencyUtils';
import TextUtils from '../../../shared/utils/TextUtils';

interface IProps {
    data: any;
    isOpen: any;
    onClose: any;
    loadData: any;
}

const ModalConfirmIFoodOrder: React.FC<IProps> = ({ isOpen, onClose, data, loadData }) => {
    const toast = useToast();
    const handleClickConfirm = useCallback(async () => {
        apiGateway
            .post('/ifood/order/confirm', {
                id: data.id
            })
            .then((response) => {
                const { status } = response;
                if (status === HTTP.STATUS.SUCCESS) {
                    onClose();
                    toast({
                        title: 'Pedido Confirmado',
                        description: 'Seu pedido foi confirmado',
                        status: 'success',
                        duration: 4000,
                        isClosable: true
                    });
                    loadData();
                }
            });
    }, [data]);

    return (
        data && (
            <>
                <Modal
                    isOpen={isOpen}
                    onClose={onClose}
                    isCentered
                    initialFocusRef={null}
                    size="lg"
                >
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader fontWeight="500">Confirmar Pedido do IFood</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody pb="8px">
                            <Flex flexDirection="column">
                                <Flex width="100%" justifyContent="space-between">
                                    <Flex width="100%" flexDirection="column" fontSize="14px">
                                        <Text as="h1" fontSize="16px" mb="8px" fontWeight="500">
                                            {`Pedido - ${data.displayId}`}
                                        </Text>

                                        <Flex width="100%">
                                            <Flex width="48%" wrap="wrap">
                                                <Flex width="100%">
                                                    <Text fontSize="16px" mb="8px" fontWeight="500">
                                                        Comprador
                                                    </Text>
                                                </Flex>
                                                <Flex
                                                    width="100%"
                                                    flexDirection="column"
                                                    fontSize="14px"
                                                >
                                                    <Text>{`Nome: ${data.customer.name}`}</Text>
                                                    <Text>
                                                        {`CPF: ${TextUtils.maskCPF(
                                                            data.customer.documentNumber || ''
                                                        )}`}
                                                    </Text>

                                                    <Text>
                                                        {`Telefone: ${TextUtils.mask(
                                                            data.customer.phone.number,
                                                            '#############'
                                                        )}`}
                                                    </Text>
                                                </Flex>
                                            </Flex>

                                            <Flex width="48%" wrap="wrap">
                                                <Flex width="100%">
                                                    <Text fontSize="16px" mb="8px" fontWeight="500">
                                                        Entrega
                                                    </Text>
                                                </Flex>
                                                <Flex
                                                    width="100%"
                                                    flexDirection="column"
                                                    fontSize="14px"
                                                >
                                                    <Text>{`Cidade: ${data.delivery.deliveryAddress.city}`}</Text>
                                                    <Text>{`Bairro: ${data.delivery.deliveryAddress.neighborhood}`}</Text>
                                                    <Text>{`Endereço: ${data.delivery.deliveryAddress.streetName}. ${data.delivery.deliveryAddress.streetNumber}`}</Text>
                                                    <Text>{`Complemento: ${data.delivery.deliveryAddress.complement}. ${data.delivery.deliveryAddress.streetNumber}`}</Text>
                                                </Flex>
                                            </Flex>
                                        </Flex>

                                        <Divider width="100%" my="16px" />

                                        <Flex width="100%" flexDirection="column">
                                            <Text fontSize="16px" mb="8px" fontWeight="500">
                                                Items
                                            </Text>
                                            {data.items.map((item) => (
                                                <Flex key={item.id} style={{ gap: '4px' }}>
                                                    <Flex width="80px">
                                                        <PictureZoom
                                                            width="80px"
                                                            height="100%"
                                                            img={item.imageUrl}
                                                        />
                                                    </Flex>

                                                    <Flex flexDirection="column">
                                                        <Text fontSize="12px">{item.name}</Text>

                                                        <Flex fontWeight="500" color="black">
                                                            <Text>{item.quantity}</Text>
                                                            <Text>{`x Un. ${CurrencyUtils.numberToCurrency(
                                                                item.totalPrice
                                                            )}`}</Text>
                                                        </Flex>
                                                    </Flex>
                                                </Flex>
                                            ))}
                                            <Flex></Flex>
                                        </Flex>

                                        <Flex
                                            width="100%"
                                            backgroundColor="yellow.100"
                                            border="2px dashed"
                                            borderColor="yellow.400"
                                            px="18px"
                                            py="8px"
                                            flexDirection="column"
                                            mt="8px"
                                        >
                                            <Text color="yellow.600" fontSize="16px">
                                                Observações
                                            </Text>
                                            <Text color="gray.800" display="flex">
                                                {data.delivery.observations}
                                            </Text>
                                        </Flex>

                                        <Divider width="100%" my="16px" />
                                    </Flex>
                                </Flex>
                            </Flex>

                            <Flex
                                width="100%"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="center"
                                my="8px"
                                fontWeight="400"
                            >
                                <Flex
                                    width="48%"
                                    flexDirection="column"
                                    textAlign="center"
                                    justifyContent="center"
                                    fontWeight="400"
                                >
                                    <Text fontSize="14px">{`Taxa ${CurrencyUtils.numberToCurrency(
                                        data.total.additionalFees
                                    )}`}</Text>
                                    <Text fontSize="14px">{`Entrega ${CurrencyUtils.numberToCurrency(
                                        data.total.deliveryFee
                                    )}`}</Text>
                                    <Text fontWeight="500">{`Total ${CurrencyUtils.numberToCurrency(
                                        data.total.orderAmount
                                    )}`}</Text>
                                </Flex>
                                <Button
                                    width="48%"
                                    backgroundColor="red.500"
                                    onClick={() => handleClickConfirm()}
                                    color="white"
                                    variantColor="red"
                                    fontWeight="500"
                                >
                                    Confirmar
                                </Button>
                            </Flex>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </>
        )
    );
};

export default ModalConfirmIFoodOrder;
