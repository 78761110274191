import { format } from 'date-fns';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DayModifiers } from 'react-day-picker';
import { FaTrashAlt } from 'react-icons/fa';
import { FiCalendar } from 'react-icons/fi';

import { Button, Checkbox, Collapse, Flex, Stack, Text, useToast } from '@chakra-ui/core';
import { FormHandles } from '@unform/core';

import Form from '../../../../components/Form';
import Calendar from '../../../../components/Form/Calendar';
import Select from '../../../../components/Form/Select';
import TextArea from '../../../../components/Form/TextArea';
import { useLayout } from '../../../../layouts/default';
import Loader from '../../../../shared/components/Loader';
import { HTTP } from '../../../../shared/constants';
import apiGateway from '../../../../shared/services/apiGateway';

interface IScheduleSettingsData {
    deliveryMessage: string;
    daysOfService: 'MON_FRY' | 'MON_SAT' | 'MON_SUN';
    weekdayHours: string[];
    weekendHour: string[];
    daysOfWeekEnabled: {
        sunday: boolean;
        monday: boolean;
        tuesday: boolean;
        wednesday: boolean;
        thursday: boolean;
        friday: boolean;
        saturday: boolean;
    };
    turnsOfDay: {
        morning: {
            isEnabled: boolean;
            lastHourAccepted: string;
            endTime: string;
        };
        evening: {
            isEnabled: boolean;
            lastHourAccepted: string;
            endTime: string;
        };
        night: {
            isEnabled: boolean;
            lastHourAccepted: string;
            endTime: string;
        };
    };
    daysDisabled: Date[];
}

const ScheduleSettings: React.FC = () => {
    const { changeTitle } = useLayout();

    const formRef = useRef<FormHandles>(undefined);

    const ALL_HOURS = [
        '00:00',
        '00:30',
        '01:00',
        '01:30',
        '02:00',
        '02:30',
        '03:00',
        '03:30',
        '04:00',
        '04:30',
        '05:00',
        '05:30',
        '06:00',
        '06:30',
        '07:00',
        '07:30',
        '08:00',
        '08:30',
        '09:00',
        '09:30',
        '10:00',
        '10:30',
        '11:00',
        '11:30',
        '12:00',
        '12:30',
        '13:00',
        '13:30',
        '14:00',
        '14:30',
        '15:00',
        '15:30',
        '16:00',
        '16:30',
        '17:00',
        '17:30',
        '18:00',
        '18:30',
        '19:00',
        '19:30',
        '20:00',
        '20:30',
        '21:00',
        '21:30',
        '22:00',
        '22:30',
        '23:00',
        '23:30'
    ];

    const [scheduleSettingsData, setScheduleSettingsData] = useState<IScheduleSettingsData>(null);

    const [selectedDate, setSelectedDate] = useState(null);
    const [showCalendar, setShowCalendar] = useState(false);
    const [loading, setLoading] = useState(true);

    const toast = useToast();

    const handleSubmit = useCallback(() => {
        const data: any = formRef.current.getData();

        apiGateway
            .put('/settings/schedule_settings', {
                ...scheduleSettingsData,
                deliveryMessage: data.delivery_message,
                weekdayHours: [data.weekday_hour_one, data.weekday_hour_two],
                weekendHour: [data.weekend_hour_one, data.weekend_hour_two]
            } as IScheduleSettingsData)
            .then((response) => {
                if (response.status === HTTP.STATUS.SUCCESS) {
                    toast({
                        title: 'Registro Atualizado',
                        description: 'Configurações atualizadas com sucesso',
                        status: 'success',
                        duration: 4000,
                        isClosable: true
                    });
                }
            });
    }, [scheduleSettingsData, toast]);

    const handleChangeDaysOfService = useCallback((value: any) => {
        setScheduleSettingsData((oldState) => {
            return {
                ...oldState,
                daysOfService: value
            };
        });
    }, []);

    const handleChangeSelectedDate = useCallback((date: Date, modifiers: DayModifiers) => {
        if (modifiers.available && !modifiers.disabled) {
            setSelectedDate(date);
            setShowCalendar(false);
        }
    }, []);

    const handleBlockDay = useCallback(() => {
        setScheduleSettingsData((oldState) => {
            const daysDisabledUpdatedList = oldState.daysDisabled;

            daysDisabledUpdatedList.push(selectedDate);

            return {
                ...oldState,
                daysDisabled: daysDisabledUpdatedList
            };
        });

        setSelectedDate(null);
    }, [selectedDate]);

    const handleRemoveBlockedDay = useCallback((value) => {
        setScheduleSettingsData((oldState) => {
            const daysDisabledUpdatedList = oldState.daysDisabled;

            return {
                ...oldState,
                daysDisabled: daysDisabledUpdatedList.filter((item) => item !== value)
            };
        });
    }, []);

    useEffect(() => {
        changeTitle('Configurarações Gerais');

        apiGateway.get('/settings/schedule_settings').then((response) => {
            if (response.status === HTTP.STATUS.SUCCESS) {
                const { data } = response;

                const {
                    delivery_message,
                    days_of_service,
                    weekday_hours,
                    weekend_hour,
                    days_of_week_enabled,
                    turns_of_day,
                    days_disabled
                } = data;

                setScheduleSettingsData({
                    deliveryMessage: delivery_message,
                    daysOfService: days_of_service,
                    weekdayHours: weekday_hours.split('|'),
                    weekendHour: weekend_hour.split('|'),
                    daysOfWeekEnabled: days_of_week_enabled,
                    turnsOfDay: turns_of_day,
                    daysDisabled:
                        days_disabled !== ''
                            ? days_disabled.split('|').map((item) => {
                                  return new Date(item);
                              })
                            : []
                });

                setLoading(false);
            }
        });
    }, [changeTitle]);

    return (
        <Form ref={formRef as any} onSubmit={handleSubmit}>
            <Flex width="100%" justifyContent="center">
                <Flex
                    width="100%"
                    maxWidth="800px"
                    flexDirection="column"
                    fontSize="12px"
                    backgroundColor="white"
                    px="24px"
                    py="16px"
                    justifyContent="center"
                    alignItems="center"
                >
                    {scheduleSettingsData && (
                        <Flex flexDirection="column" width="100%">
                            <Flex width="100%" flexDirection="column">
                                <Text color="purple.500" fontSize="18px">
                                    Configurar Horários
                                </Text>

                                <Flex flexDirection="column" mt="8px">
                                    <Text color="purple.500" fontSize="14px">
                                        Mensagem de Entrega
                                    </Text>

                                    <TextArea
                                        name="delivery_message"
                                        defaultValue={scheduleSettingsData.deliveryMessage}
                                    />
                                </Flex>

                                <Flex flexDirection="column" mt="8px">
                                    <Text color="purple.500" fontSize="14px">
                                        Dias de Atendimento
                                    </Text>
                                    <Stack spacing={2} direction="row">
                                        <Checkbox
                                            size="sm"
                                            isChecked={
                                                scheduleSettingsData.daysOfService === 'MON_FRY'
                                            }
                                            onClick={() => handleChangeDaysOfService('MON_FRY')}
                                            variantColor="green"
                                        >
                                            <Text fontSize="12px">Segunda á Sexta</Text>
                                        </Checkbox>

                                        <Checkbox
                                            size="sm"
                                            isChecked={
                                                scheduleSettingsData.daysOfService === 'MON_SAT'
                                            }
                                            onClick={() => handleChangeDaysOfService('MON_SAT')}
                                            variantColor="green"
                                        >
                                            <Text fontSize="12px">Segunda á Sábado</Text>
                                        </Checkbox>

                                        <Checkbox
                                            size="sm"
                                            isChecked={
                                                scheduleSettingsData.daysOfService === 'MON_SUN'
                                            }
                                            onClick={() => handleChangeDaysOfService('MON_SUN')}
                                            variantColor="green"
                                        >
                                            <Text fontSize="12px">Segunda á Domingo</Text>
                                        </Checkbox>
                                    </Stack>
                                </Flex>

                                <Flex flexDirection="column" mt="16px">
                                    <Text color="purple.500" fontSize="14px">
                                        Horário de Segunda a Sexta
                                    </Text>
                                    <Flex>
                                        <Stack direction="row" spacing={2}>
                                            <Select
                                                name="weekday_hour_one"
                                                label="De"
                                                size="sm"
                                                width="96px"
                                                defaultValue={scheduleSettingsData.weekdayHours[0]}
                                            >
                                                {ALL_HOURS.map((hour) => (
                                                    <option key={hour} value={hour}>
                                                        {hour}
                                                    </option>
                                                ))}
                                            </Select>

                                            <Select
                                                name="weekday_hour_two"
                                                label="Até"
                                                size="sm"
                                                width="96px"
                                                defaultValue={scheduleSettingsData.weekdayHours[1]}
                                            >
                                                {ALL_HOURS.map((hour) => (
                                                    <option key={hour} value={hour}>
                                                        {hour}
                                                    </option>
                                                ))}
                                            </Select>
                                        </Stack>
                                    </Flex>
                                </Flex>

                                <Flex flexDirection="column" mt="8px">
                                    <Text color="purple.500" fontSize="14px">
                                        Horário de Final de Semana
                                    </Text>
                                    <Flex>
                                        <Stack direction="row" spacing={2}>
                                            <Select
                                                name="weekend_hour_one"
                                                label="De"
                                                size="sm"
                                                width="96px"
                                                defaultValue={scheduleSettingsData.weekendHour[0]}
                                            >
                                                {ALL_HOURS.map((hour) => (
                                                    <option key={hour} value={hour}>
                                                        {hour}
                                                    </option>
                                                ))}
                                            </Select>

                                            <Select
                                                name="weekend_hour_two"
                                                label="Até"
                                                size="sm"
                                                width="96px"
                                                defaultValue={scheduleSettingsData.weekendHour[1]}
                                            >
                                                {ALL_HOURS.map((hour) => (
                                                    <option key={hour} value={hour}>
                                                        {hour}
                                                    </option>
                                                ))}
                                            </Select>
                                        </Stack>
                                    </Flex>
                                </Flex>
                            </Flex>

                            <Flex width="100%" mt="24px" flexDirection="column">
                                <Flex flexDirection="column" mt="16px">
                                    <Text color="purple.500" fontSize="14px">
                                        Bloquear Entregas
                                    </Text>

                                    <Flex flexDirection="column" mr="16px">
                                        <Flex alignItems="center">
                                            <Button
                                                width="184px"
                                                backgroundColor="purple.500"
                                                borderRadius="2px"
                                                size="sm"
                                                height="32px"
                                                py="0px"
                                                fontSize="12px"
                                                display="flex"
                                                alignItems="center"
                                                _focus={{
                                                    outline: 'none'
                                                }}
                                                _hover={{
                                                    backgroundColor: 'purple.500'
                                                }}
                                                onClick={() =>
                                                    setShowCalendar((oldState) => !oldState)
                                                }
                                                color="white"
                                            >
                                                <FiCalendar size={16} />
                                                <Text ml="8px" fontWeight="500">
                                                    {selectedDate
                                                        ? `De ${format(selectedDate, 'dd/MM/yyyy')}`
                                                        : 'Selecione uma Data'}
                                                </Text>
                                            </Button>

                                            {selectedDate && (
                                                <Text
                                                    fontSize="16px"
                                                    color="red.500"
                                                    textDecoration="underline"
                                                    cursor="pointer"
                                                    ml="16px"
                                                    onClick={() => handleBlockDay()}
                                                >
                                                    Bloquear
                                                </Text>
                                            )}
                                        </Flex>

                                        <Collapse width="100%" isOpen={showCalendar} display="flex">
                                            <Calendar
                                                isErrored={false}
                                                selectedDate={selectedDate}
                                                handleDateChange={handleChangeSelectedDate}
                                                handleMonthChange={undefined}
                                            />
                                        </Collapse>
                                    </Flex>
                                </Flex>

                                <Flex width="400px" flexDirection="column" mt="16px">
                                    <Flex width="100%" backgroundColor="red.500" px="16px" py="4px">
                                        <Text color="white">Dias Bloqueados</Text>
                                    </Flex>
                                    <Flex
                                        width="100%"
                                        backgroundColor="gray.200"
                                        px="16px"
                                        py="4px"
                                    >
                                        {scheduleSettingsData.daysDisabled.length <= 0 && (
                                            <Text>Nenhum dia bloqueado</Text>
                                        )}

                                        {scheduleSettingsData.daysDisabled.length > 0 && (
                                            <Flex flexDirection="column">
                                                {scheduleSettingsData.daysDisabled.map(
                                                    (dayDisabled) => (
                                                        <Flex
                                                            key={String(dayDisabled)}
                                                            alignItems="center"
                                                            mb="4px"
                                                        >
                                                            <Text width="80px">
                                                                {format(dayDisabled, 'dd/MM/yyyy')}
                                                            </Text>

                                                            <Flex
                                                                backgroundColor="red.500"
                                                                color="white"
                                                                p="4px"
                                                                borderRadius="2px"
                                                                cursor="pointer"
                                                                onClick={() =>
                                                                    handleRemoveBlockedDay(
                                                                        dayDisabled
                                                                    )
                                                                }
                                                            >
                                                                <FaTrashAlt size={12} />
                                                            </Flex>
                                                        </Flex>
                                                    )
                                                )}
                                            </Flex>
                                        )}
                                    </Flex>
                                </Flex>
                            </Flex>
                            <Flex
                                backgroundColor="green.500"
                                px="24px"
                                py="4px"
                                justifyContent="center"
                                borderRadius="2px"
                                ml="auto"
                                cursor="pointer"
                                onClick={() => handleSubmit()}
                            >
                                <Text color="white" fontSize="16px">
                                    Salvar
                                </Text>
                            </Flex>
                        </Flex>
                    )}

                    {loading && <Loader />}
                </Flex>
            </Flex>
        </Form>
    );
};

export default ScheduleSettings;
