import React, { useCallback } from 'react';
import Dropzone from 'react-dropzone';
import { FiAlertTriangle, FiCamera, FiCheck } from 'react-icons/fi';

import { Flex, PseudoBox, Text } from '@chakra-ui/core';

import axios from 'axios';

interface IProps {
    setUploadedImage: any;
}

const FileUploadLogo: React.FC<IProps> = ({ setUploadedImage }) => {
    const handleOnUploadImage = useCallback(
        async (file: any) => {
            try {
                const image = {
                    key: Math.random().toString(36).substr(2, 9),
                    id: null,
                    file: file[0],
                    name: file[0].name,
                    preview: URL.createObjectURL(file[0]),
                    progress: 0,
                    uploaded: false,
                    error: false,
                    url: null,
                    is_cover: false
                };

                setUploadedImage(image);

                const data = new FormData();
                data.append('file', image.file, image.name);

                const response = await axios.post(
                    'https://server.eflorista.com.br/api/v1/pages/upload',
                    data,
                    {
                        onUploadProgress: (e) => {
                            const progress = parseInt(
                                Math.round((e.loaded * 100) / e.total).toString(),
                                10
                            );

                            setUploadedImage({
                                ...image,
                                progress
                            });
                        }
                    }
                );

                setUploadedImage({
                    ...image,
                    url: response.data.location,
                    uploaded: true
                });
            } catch (error) {
                console.log('log', error);
                setUploadedImage((oldState) => {
                    return {
                        ...oldState,
                        error: true
                    };
                });
            }
        },
        [setUploadedImage]
    );

    return (
        <Dropzone
            accept={{
                'image/png': ['.jpg', '.jpeg', '.png']
            }}
            onDropAccepted={handleOnUploadImage}
        >
            {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                <Flex
                    flexDirection="column"
                    alignItems="center"
                    mr="16px"
                    {...getRootProps()}
                    outline="none"
                >
                    <input {...getInputProps()} />
                    <PseudoBox
                        mt="10px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        border="2px dashed"
                        borderColor="gray.600"
                        width="100%"
                        height="160px"
                        _hover={{
                            cursor: 'pointer'
                        }}
                    >
                        {!isDragActive && !isDragReject && (
                            <>
                                <FiCamera size={40} />
                                <Text fontSize="12px" mt="16px">
                                    Arraste ou clique aqui
                                </Text>
                            </>
                        )}

                        {isDragActive && !isDragReject && (
                            <Flex flexDirection="column" color="green.500" alignItems="center">
                                <FiCheck size={40} />
                                <Text fontSize="12px" mt="16px">
                                    Solte o arquivo aqui
                                </Text>
                            </Flex>
                        )}

                        {isDragReject && (
                            <Flex flexDirection="column" color="pink.500" alignItems="center">
                                <FiAlertTriangle size={40} />
                                <Text fontSize="12px" mt="16px">
                                    Arquivo não suportado
                                </Text>
                            </Flex>
                        )}
                    </PseudoBox>
                </Flex>
            )}
        </Dropzone>
    );
};

export default FileUploadLogo;
