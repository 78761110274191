import React from 'react';

import { Checkbox, Flex, Stack, Text } from '@chakra-ui/core';

import Select from '../../../../components/Form/Select';

// import { Container } from './styles';

const ALL_HOURS = [
    '00:00',
    '00:30',
    '01:00',
    '01:30',
    '02:00',
    '02:30',
    '03:00',
    '03:30',
    '04:00',
    '04:30',
    '05:00',
    '05:30',
    '06:00',
    '06:30',
    '07:00',
    '07:30',
    '08:00',
    '08:30',
    '09:00',
    '09:30',
    '10:00',
    '10:30',
    '11:00',
    '11:30',
    '12:00',
    '12:30',
    '13:00',
    '13:30',
    '14:00',
    '14:30',
    '15:00',
    '15:30',
    '16:00',
    '16:30',
    '17:00',
    '17:30',
    '18:00',
    '18:30',
    '19:00',
    '19:30',
    '20:00',
    '20:30',
    '21:00',
    '21:30',
    '22:00',
    '22:30',
    '23:00',
    '23:30'
];

const DAYS_OF_WEEK = [
    {
        key: 'MONDAY',
        name: 'Segunda-feira'
    },
    {
        key: 'TUESDAY',
        name: 'Terça-feira'
    },
    {
        key: 'WEDNESDAY',
        name: 'Quarta-feira'
    },
    {
        key: 'THURSDAY',
        name: 'Quinta-feira'
    },
    {
        key: 'FRIDAY',
        name: 'Sexta-feira'
    },
    {
        key: 'SATURDAY',
        name: 'Sabado'
    },
    {
        key: 'SUNDAY',
        name: 'Domingo'
    }
];

interface ITurnProps {
    timeSettings: any;
    handleChangeDayIsActive: any;
    handleChangeTurnIsActive: any;
}

const Turns: React.FC<ITurnProps> = ({
    timeSettings,
    handleChangeDayIsActive,
    handleChangeTurnIsActive
}) => {
    return (
        <Flex direction="column" my="24px">
            {DAYS_OF_WEEK.map((day) => (
                <Stack key={day.key} direction="row" alignItems="center" spacing={6} mb="16px">
                    <Flex width="120px">
                        <Checkbox
                            variantColor="green"
                            size="md"
                            isChecked={timeSettings?.[day.key].isActive}
                            onChange={() => handleChangeDayIsActive(day.key)}
                        />
                        <Text ml="8px">{day.name}</Text>
                    </Flex>
                    <Flex flexDirection="column" alignItems="center">
                        {day.key === 'MONDAY' && (
                            <Flex justifyContent="center" mb="8px">
                                <Text>Manhã</Text>
                            </Flex>
                        )}

                        <Flex width="100%" justifyContent="space-between">
                            <Text fontSize="11px">Aceita pedido até</Text>
                            <Text fontSize="11px">Entrega pedido até</Text>
                        </Flex>

                        <Flex alignItems="center">
                            <Checkbox
                                variantColor="green"
                                size="md"
                                isChecked={timeSettings?.[day.key].MORNING.isActive}
                                onChange={() => handleChangeTurnIsActive(day.key, 'MORNING')}
                            />

                            <Select
                                name={`${day.key}_MORNING_START_HOUR`}
                                label=""
                                size="sm"
                                width="88px"
                                mb="0px"
                                mx="8px"
                                defaultValue={timeSettings?.[day.key].MORNING.startHour}
                            >
                                {ALL_HOURS.map((hour) => (
                                    <option key={hour} value={hour}>
                                        {hour}
                                    </option>
                                ))}
                            </Select>

                            <Select
                                name={`${day.key}_MORNING_END_HOUR`}
                                label=""
                                size="sm"
                                width="88px"
                                mb="0px"
                                defaultValue={timeSettings?.[day.key].MORNING.endHour}
                            >
                                {ALL_HOURS.map((hour) => (
                                    <option key={hour} value={hour}>
                                        {hour}
                                    </option>
                                ))}
                            </Select>
                        </Flex>
                    </Flex>

                    <Flex flexDirection="column" alignItems="center">
                        {day.key === 'MONDAY' && (
                            <Flex justifyContent="center" mb="8px">
                                <Text>Tarde</Text>
                            </Flex>
                        )}

                        <Flex width="100%" justifyContent="space-between">
                            <Text fontSize="11px">Aceita pedido até</Text>
                            <Text fontSize="11px">Entrega pedido até</Text>
                        </Flex>

                        <Flex alignItems="center">
                            <Checkbox
                                variantColor="green"
                                size="md"
                                isChecked={timeSettings?.[day.key].EVENING.isActive}
                                onChange={() => handleChangeTurnIsActive(day.key, 'EVENING')}
                            />

                            <Select
                                name={`${day.key}_EVENING_START_HOUR`}
                                label=""
                                size="sm"
                                width="88px"
                                mb="0px"
                                mx="8px"
                                defaultValue={timeSettings?.[day.key].EVENING.startHour}
                            >
                                {ALL_HOURS.map((hour) => (
                                    <option key={hour} value={hour}>
                                        {hour}
                                    </option>
                                ))}
                            </Select>

                            <Select
                                name={`${day.key}_EVENING_END_HOUR`}
                                label=""
                                size="sm"
                                width="88px"
                                mb="0px"
                                defaultValue={timeSettings?.[day.key].EVENING.endHour}
                            >
                                {ALL_HOURS.map((hour) => (
                                    <option key={hour} value={hour}>
                                        {hour}
                                    </option>
                                ))}
                            </Select>
                        </Flex>
                    </Flex>

                    <Flex flexDirection="column" alignItems="center">
                        {day.key === 'MONDAY' && (
                            <Flex justifyContent="center" mb="8px">
                                <Text>Noite</Text>
                            </Flex>
                        )}

                        <Flex width="100%" justifyContent="space-between">
                            <Text fontSize="11px">Aceita pedido até</Text>
                            <Text fontSize="11px">Entrega pedido até</Text>
                        </Flex>

                        <Flex alignItems="center">
                            <Checkbox
                                variantColor="green"
                                size="md"
                                isChecked={timeSettings?.[day.key].NIGHT.isActive}
                                onChange={() => handleChangeTurnIsActive(day.key, 'NIGHT')}
                            />

                            <Select
                                name={`${day.key}_NIGHT_START_HOUR`}
                                label=""
                                size="sm"
                                width="88px"
                                mb="0px"
                                mx="8px"
                                defaultValue={timeSettings?.[day.key].NIGHT.startHour}
                            >
                                {ALL_HOURS.map((hour) => (
                                    <option key={hour} value={hour}>
                                        {hour}
                                    </option>
                                ))}
                            </Select>

                            <Select
                                name={`${day.key}_NIGHT_END_HOUR`}
                                label=""
                                size="sm"
                                width="88px"
                                mb="0px"
                                defaultValue={timeSettings?.[day.key].NIGHT.endHour}
                            >
                                {ALL_HOURS.map((hour) => (
                                    <option key={hour} value={hour}>
                                        {hour}
                                    </option>
                                ))}
                            </Select>
                        </Flex>
                    </Flex>

                    <Flex flexDirection="column" alignItems="center">
                        {day.key === 'MONDAY' && (
                            <Flex justifyContent="center" mb="8px">
                                <Text>Turno Único</Text>
                            </Flex>
                        )}

                        <Flex width="100%" justifyContent="space-between">
                            <Text fontSize="11px">Aceita pedido até</Text>
                            <Text fontSize="11px">Entrega pedido até</Text>
                        </Flex>

                        <Flex alignItems="center">
                            <Checkbox
                                variantColor="green"
                                size="md"
                                isChecked={timeSettings?.[day.key].UNIQUE.isActive}
                                onChange={() => handleChangeTurnIsActive(day.key, 'UNIQUE')}
                            />

                            <Select
                                name={`${day.key}_UNIQUE_START_HOUR`}
                                label=""
                                size="sm"
                                width="88px"
                                mb="0px"
                                mx="8px"
                                defaultValue={timeSettings?.[day.key].UNIQUE.startHour}
                            >
                                {ALL_HOURS.map((hour) => (
                                    <option key={hour} value={hour}>
                                        {hour}
                                    </option>
                                ))}
                            </Select>

                            <Select
                                name={`${day.key}_UNIQUE_END_HOUR`}
                                label=""
                                size="sm"
                                width="88px"
                                mb="0px"
                                defaultValue={timeSettings?.[day.key].UNIQUE.endHour}
                            >
                                {ALL_HOURS.map((hour) => (
                                    <option key={hour} value={hour}>
                                        {hour}
                                    </option>
                                ))}
                            </Select>
                        </Flex>
                    </Flex>
                </Stack>
            ))}
        </Flex>
    );
};

export default Turns;
