import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiEdit, FiSearch, FiTrash2 } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import { Box, Checkbox, Divider, Flex, PseudoBox, Text } from '@chakra-ui/core';

import { RiFileExcel2Line } from 'react-icons/ri';
import Button from '../../../components/Button';
import InputSearch from '../../../components/Form/InputSearch';
import List from '../../../components/List';
import ListEmpty from '../../../components/List/ListEmpty';
import ListRow from '../../../components/List/ListRow';
import { useLayout } from '../../../layouts/default';
import { useAuth } from '../../../shared/hooks/useAuth';
import apiGateway from '../../../shared/services/apiGateway';
import DateUtils from '../../../shared/utils/DateUtils';
import TextUtils from '../../../shared/utils/TextUtils';

const BuyersPage: React.FC = () => {
    const navigate = useNavigate();
    const { changeTitle } = useLayout();
    const { store } = useAuth();

    const inputQueryRef = useRef<HTMLInputElement>(undefined);

    const [selectedBuyersId, setSelectedBuyersId] = useState([]);

    const [buyers, setBuyers] = useState([]);

    const handleGetBuyers = useCallback(() => {
        apiGateway.get('/buyers').then((response) => {
            setBuyers(response.data);
        });
    }, [buyers]);

    const handleDeleteBuyers = useCallback(() => {
        apiGateway
            .delete('/buyers', {
                params: {
                    ids: selectedBuyersId
                }
            })
            .then((response) => {
                if (response.status === 200) {
                    navigate(0);
                }
            });
    }, [buyers]);

    const handleChangeSelectedBuyersIds = useCallback(
        (input: any) => {
            let list = selectedBuyersId;

            if (input.checked) {
                if (!selectedBuyersId.includes(input.value as never)) {
                    list.push(input.value as never);
                }
            } else {
                list = list.filter((item) => item !== input.value);
            }

            setSelectedBuyersId(list);
        },
        [selectedBuyersId]
    );

    const handleSearchBuyers = useCallback(() => {
        const query = inputQueryRef?.current?.value;
        if (query.length >= 3) {
            apiGateway.get(`/buyers?name=${query}`).then((response) => {
                setBuyers(response.data);
            });
        } else {
            handleGetBuyers();
        }
    }, []);

    const handleEditBuyers = useCallback(
        (id: string) => {
            navigate(`/clients/edit/${id}`);
        },
        [navigate]
    );

    const BuyerRow: React.FC<any> = ({ style, index, data }) => {
        return (
            <ListRow
                style={{
                    ...style
                }}
                index={index}
            >
                <Flex width="100%" justifyContent="center" maxWidth="40px">
                    <Checkbox
                        variantColor="green"
                        size="sm"
                        title="Selecionar Cliente"
                        onChange={(e) =>
                            handleChangeSelectedBuyersIds(e.currentTarget)
                        }
                        value={data[index].id}
                        borderColor="gray.700"
                        style={{
                            outline: 'none'
                        }}
                    />
                </Flex>

                <Flex width="100%" justifyContent="center">
                    <Text>{data[index].name}</Text>
                </Flex>

                <Flex width="100%" justifyContent="center">
                    <Text>
                        {TextUtils.maskTelephone(data[index].telephone)}
                    </Text>
                </Flex>

                <Flex width="100%" justifyContent="center">
                    <Text>{data[index].email}</Text>
                </Flex>

                <Flex width="100%" justifyContent="center">
                    <Text>{TextUtils.maskCPF(data[index].document)}</Text>
                </Flex>

                <Flex width="100%" justifyContent="center">
                    <Text>{data[index].type}</Text>
                </Flex>

                <Flex width="100%" justifyContent="center">
                    <Text>{DateUtils.format(data[index].created_at)}</Text>
                </Flex>

                <Flex width="100%" justifyContent="center" maxWidth="104px">
                    <PseudoBox
                        border="2px solid"
                        borderColor="purple.500"
                        title="Editar Cliente"
                        p="8px"
                        backgroundColor="purple.500"
                        color="white"
                        borderRadius="2px"
                        cursor="pointer"
                        onClick={() => handleEditBuyers(data[index].id)}
                    >
                        <FiEdit size={14} />
                    </PseudoBox>
                </Flex>
            </ListRow>
        );
    };

    useEffect(() => {
        changeTitle('Clientes');
        handleGetBuyers();
    }, [changeTitle]);

    return (
        <Flex width="100%" flexDirection="column">
            <Flex width="100%" mb="8px" alignItems="center">
                <Button
                    backgroundColor="purple.500"
                    width="200px"
                    fontSize="16px"
                    height="40px"
                    mr="16px"
                    title="Cadastrar Cliente"
                    onClick={() => navigate('/clients/register')}
                >
                    Novo Cliente
                </Button>

                <Button
                    backgroundColor="pink.500"
                    width="56px"
                    p="0px"
                    fontSize="16px"
                    height="40px"
                    mr="16px"
                    title="Excluir Clientes"
                    _hover={{
                        backgroundColor: 'pink.500'
                    }}
                    onClick={() => handleDeleteBuyers()}
                >
                    <FiTrash2 size={20} />
                </Button>

                <Box ml="auto" width="40%">
                    <InputSearch
                        ref={inputQueryRef}
                        name="name"
                        placeholder="Pesquisar por nome de cliente"
                        onEnterPress={() => handleSearchBuyers()}
                        onKeyUp={() => handleSearchBuyers()}
                        size="sm"
                    />
                </Box>

                <Button
                    backgroundColor="green.500"
                    width="40px"
                    borderRadius="2px"
                    p="0px"
                    fontSize="12px"
                    height="32px"
                    mr="8px"
                    title="Pesquisar Clientes"
                    _hover={{
                        backgroundColor: 'purple.500'
                    }}
                    onClick={() => handleSearchBuyers()}
                >
                    <FiSearch size={18} />
                </Button>

                <a
                    target="_blank"
                    rel="noreferrer"
                    style={{ marginRight: '16px', fontSize: '18px' }}
                    title="Exportar Clientes"
                    href={`${process.env.REACT_APP_API_URL}/buyers/export/excel/${store}`}
                >
                    <RiFileExcel2Line />
                </a>
            </Flex>

            <Flex width="100%" flexDirection="column">
                <Flex
                    width="100%"
                    flexDirection="column"
                    backgroundColor="white"
                    px="24px"
                >
                    <Flex
                        width="100%"
                        py="8px"
                        justifyContent="space-between"
                        flexWrap="wrap"
                    >
                        <Flex
                            width="50%"
                            justifyContent="flex-start"
                            alignItems="center"
                        >
                            <Text mr="16px" fontSize="24px" color="purple.500">
                                Listagem
                            </Text>
                        </Flex>
                    </Flex>

                    <Divider my="0px" />
                    <Flex
                        width="100%"
                        py="8px"
                        justifyContent="space-between"
                        fontSize="14px"
                        maxWidth="100%"
                    >
                        <Flex
                            width="100%"
                            justifyContent="center"
                            maxWidth="40px"
                        >
                            <Text>#</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center">
                            <Text>Nome</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center">
                            <Text>Telefone</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center">
                            <Text>Email</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center">
                            <Text>Documento</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center">
                            <Text>Tipo</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center">
                            <Text>Cadastrado Em</Text>
                        </Flex>

                        <Flex
                            width="100%"
                            justifyContent="center"
                            maxWidth="104px"
                        >
                            <Text>Ação</Text>
                        </Flex>
                    </Flex>
                    <Divider my="0px" />

                    {buyers.length > 0 && (
                        <List item={buyers} row={BuyerRow} itemHeight={48} />
                    )}

                    {buyers.length <= 0 && <ListEmpty />}
                </Flex>
            </Flex>
        </Flex>
    );
};

export default BuyersPage;
